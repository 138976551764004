import { sendAnalyticsEvent } from '@faststore/sdk'
import type { CurrencyCode, RemoveFromCartEvent } from '@faststore/sdk'
import type { AnalyticsItem } from 'src/sdk/analytics/types'
import type { ProductPropertieType } from 'src/components/ui/QuantitySelector/QuantitySelector'
import { useCallback } from 'react'
import removeProductKpiFromOrderForm from 'src/utils/kpiToCart/removeProductKpiFromOrderForm'

import { useSession } from '../session'
import { useRemoveFromCart } from '../analytics/hooks/useRemoveFromCartEvent'
import { cartStore, useCart } from './index'
import type { CartItem } from '.'

export const useRemoveButton = (item: CartItem | null) => {
  const {
    currency: { code },
  } = useSession()

  const { sendRemoveFromCartEvent } = useRemoveFromCart()

  const { id } = useCart()

  const onClick = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      e.preventDefault()

      if (!item) {
        return
      }

      const productID =
        item?.itemOffered?.isVariantOf?.additionalProperty?.filter(
          (propertie: ProductPropertieType) => propertie.name === 'Cód'
        )

      const valueProductID: string = productID?.[0]?.value
        ? productID?.[0]?.value
        : item?.itemOffered?.gtin

      sendRemoveFromCartEvent({
        currency: code,
        value: Number((item.price * item.quantity).toFixed(2)),
        item_id: item.itemOffered?.sku,
        item_name: item.itemOffered.name,
        price: item.listPrice,
        quantity: item.quantity,
        discount: Number(
          ((item.listPrice - item.price) * item.quantity).toFixed(2)
        ),
        item_brand: item.itemOffered.brand.name ?? null,
        item_collection:
          item.itemOffered.isVariantOf.additionalProperty.find(
            (property) =>
              property.name.toLowerCase() === 'coleção'.toLowerCase()
          )?.value ?? null,
        item_type: item.itemOffered.name?.match(/^\p{L}+/u)?.[0] ?? null,
        item_reference: valueProductID,
        item_material:
          item.itemOffered.isVariantOf.additionalProperty.find(
            (property) =>
              property.name.toLowerCase() === 'material'.toLowerCase()
          )?.value ??
          item.itemOffered.isVariantOf.additionalProperty.find(
            (property) =>
              property.name.toLowerCase() === '1-material'.toLowerCase()
          )?.value ??
          item.itemOffered.isVariantOf.additionalProperty.find(
            (property) =>
              property.name.toLowerCase() ===
              'material da pulseira'.toLowerCase()
          )?.value ??
          null,
        item_size:
          item.itemOffered.isVariantOf.additionalProperty.find(
            (property) =>
              property.name.toLowerCase() === 'tamanho'.toLowerCase()
          )?.value ??
          item.itemOffered.additionalProperty.find(
            (property) => property.name.toLowerCase() === 'aro'.toLowerCase()
          )?.value ??
          item.itemOffered.isVariantOf.additionalProperty.find(
            (property) =>
              property.name.toLowerCase() === 'comprimento aprox'.toLowerCase()
          )?.value ??
          item.itemOffered.isVariantOf.additionalProperty.find(
            (property) =>
              property.name.toLowerCase() === 'altura aprox'.toLowerCase()
          )?.value ??
          item.itemOffered.isVariantOf.additionalProperty.find(
            (property) =>
              property.name.toLowerCase() === 'largura aprox'.toLowerCase()
          )?.value ??
          null,
        item_stone:
          item.itemOffered.isVariantOf.additionalProperty.find(
            (property) => property.name.toLowerCase() === 'pedras'.toLowerCase()
          )?.value ??
          item.itemOffered.isVariantOf.additionalProperty.find(
            (property) =>
              property.name.toLowerCase() === 'pedra_valor'.toLowerCase()
          )?.value ??
          null,
        item_weight: item.itemOffered.name.match(/(\d+)\s*k/i)?.[0] ?? null,
        item_audience:
          item.itemOffered.isVariantOf.additionalProperty.find(
            (property) =>
              property.name.toLowerCase() === 'sugestão'.toLowerCase()
          )?.value ?? null,
      })

      sendAnalyticsEvent<RemoveFromCartEvent<AnalyticsItem>>({
        name: 'remove_from_cart',
        params: {
          currency: code as CurrencyCode,
          value: item?.price * item.quantity,
          items: [
            {
              item_id: item.itemOffered?.sku,
              item_name: item.itemOffered?.isVariantOf?.name,
              item_brand: item.itemOffered?.brand?.name,
              item_variant: item.itemOffered?.sku,
              quantity: item.quantity,
              price: item?.price,
              discount: item?.listPrice - item?.price,
              currency: code as CurrencyCode,
              item_variant_name: item.itemOffered?.name,
              product_reference_id: valueProductID,
              dimension1: item.itemOffered?.gtin,
            },
          ],
        },
      })

      cartStore.removeItem(item.id)

      removeProductKpiFromOrderForm(id, item?.itemOffered?.sku)
    },
    [code, item]
  )

  return {
    onClick,
    'data-testid': 'remove-from-cart-button',
    'data-sku': item?.itemOffered?.sku,
  }
}
